@font-face {
    font-family: "Graphik";
    src: url("fonts/graphikRegular.otf") format("otf"),
    url("fonts/graphikRegular.svg") format("svg"),
    url("fonts/graphikRegular.ttf") format("ttf"),
    url("fonts/graphikRegular.woff") format("woff");
    font-weight: normal;
}

@font-face {
    font-family: "Graphik";
    src: url("fonts/graphikSemibold.otf") format("otf"),
    url("fonts/graphikSemibold.svg") format("svg"),
    url("fonts/graphikSemibold.ttf") format("ttf"),
    url("fonts/graphikSemibold.woff") format("woff");
    font-weight: bold;
}

@font-face {
    font-family: "Graphik";
    src: url("fonts/graphikMedium.eot") format("eot"),
    url("fonts/graphikMedium.svg") format("svg"),
    url("fonts/graphikMedium.ttf") format("ttf"),
    url("fonts/graphikMedium.woff") format("woff");
    font-weight: 500;
}

:root {
    --wz-primary-color: #0032FA;
    --wz-font-family: "Graphik";
}

body.gemino-error {
    font-family: var(--wz-font-family);
    background-image: url('./img/404.png');
    background-color: #FFF; /* Used if the image is unavailable */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
    margin: 0;
    overflow-x: hidden;
    min-width: 320px;
    font-size: 14px;
}

.gemino-error .gemino-error-container {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.gemino-error .gemino-error-content {
    display: block;
    padding: 15px;
}

.gemino-error .gemino-error-container > h2 {
    font-weight: bold;
    font-size: 24px;
}

.gemino-error .gemino-referrer {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.gemino-error .gemino-error-container > p {
    margin: 0;
}

.gemino-error .gemino-error-container > p:last-child {
    margin: 10px 0 0;
}

.gemino-error .gemino-error-container .gemino-status {
    font-family: 'Space Mono', monospace;
    font-weight: bold;
    font-size: 16px;
}

.gemino-error .gemino-error-container .gemino-buttons-group {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.gemino-error .gemino-error-container .gemino-buttons-group > a {
    border-radius: 5px;
    border: 1px solid var(--wz-primary-color);
    background-color: var(--wz-primary-color);
    color: #FFF;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
    margin-bottom: 10px;
}

.gemino-error .gemino-error-container .gemino-buttons-group > a:hover {
    background-color: rgba(0, 60, 211,1);
}

.gemino-error .gemino-error-container .gemino-buttons-group > a.gemino-btn-inverted {
    background-color: #FFF;
    color: var(--wz-primary-color);
}


.gemino-error .gemino-error-container .gemino-buttons-group > a.gemino-btn-inverted:hover {
    color: rgba(0, 60, 211,1);
    border-color: rgba(0, 60, 211,1);
}

@media (min-width: 850px) {
    .gemino-error .gemino-error-container .gemino-buttons-group {
        flex-direction: row;
    }

    .gemino-error .gemino-error-container .gemino-buttons-group > a {
        margin-right: 10px;
    }
}
